import React from 'react';
import { PuffLoader } from 'react-spinners';
import '../../css/spinner.css'

const Spinner = ({ loading }) => {
    return (
        <div className={`spinner-overlay ${loading ? '' : 'hidden'}`}>
            <div className="spinner-container">
                <PuffLoader color={"#AA4332"} loading={loading} size={100} />
            </div>
        </div>
    );
};

export default Spinner;
