import Header from "./Header";

function Error404() {
    return (
        <div className="App">
            <Header style={2} screen={"404"} />
            <div className="Content">
                <div className="container text-center py-5">
                    <h1>Page Not Found</h1>
                    <p>Error 404: The requested page does not exist.</p>
                </div>
            </div>
        </div>
    );
}

export default Error404;
