import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { APIENDPOINT } from '../global/Const';
import { postData } from '../global/Api';
import Spinner from '../components/common/Spinner';
import { useNavigate } from 'react-router-dom';


const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      console.log('Razorpay SDK loaded successfully');
      resolve(true);
    };
    script.onerror = () => {
      console.error('Failed to load Razorpay SDK');
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({
  key,
  amount,
  currency,
  name,
  description,
  image,
  order_id,
  callback_url,
  prefill,
  notes,
  theme,
  payment_gateway
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCallback = async (response, redirect) => {
    try {
      const formData = response;
      setIsLoading(true)
      const responseApi = await postData(APIENDPOINT.UPDATEPAYMENTSTATUS, formData);
      if (responseApi.response_code === 200 && responseApi.status === true) {
        toast.success('Payment status updated successfully');
        setIsLoading(false);
        window.location.href = redirect
      } else if (responseApi.message) {
        toast.error(responseApi.message);
        setIsLoading(false);
      } else {
        toast.error('Something went wrong while updating payment status');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error updating payment status:', error);
      toast.error('Failed to update payment status');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadRazorpay = async () => {
      const loaded = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      );

      if (!loaded) {
        console.error('Razorpay SDK failed to load. Please check your internet connection.');
        return;
      }

      var options = {
        key: key,
        amount: amount,
        currency: currency,
        name: name,
        description: description,
        image: image,
        order_id: order_id,
        // callback_url: callback_url,
        prefill: prefill,
        notes: notes,
        theme: theme,
        handler: function (response) {
          rzp1.close();
          handleCallback(response, callback_url);
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    };

    loadRazorpay();

  }, [order_id, key, currency, amount]);

  return (
    <>{isLoading && <Spinner loading={isLoading} />}</>
  );
};

export default RenderRazorpay;
