import { CLEAR_DATA, SET_DATA, RESET_TO_BLANK } from "./action";

const setData = (data) => ({
    type: SET_DATA,
    payload: data
})

const clearData = () => ({
    type: CLEAR_DATA
})

const resetData = () => ({
    type: RESET_TO_BLANK
})

export { setData, clearData, resetData };