import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';
import Spinner from '../../components/common/Spinner';
import { getData } from '../../global/Api';
import { APIENDPOINT } from '../../global/Const';

function QuestionAnswer() {
    const { question } = useParams();
    const [apiResponse, setapiResponse] = useState()
    const [isLoading, setisLoading] = useState(true);
    const state = useSelector((state) => state);
    const navigate = useNavigate()

    const getSupportList = async () => {
        try {
            const responseApi = await getData(APIENDPOINT.GETALLSUPPORTLIST);
            if (responseApi.response_code === 200 && responseApi.status === true) {
                setapiResponse(responseApi)
            } else if (responseApi.message) {
                toast.error(responseApi.message);
                setisLoading(false);
            } else {
                toast.error("Something Went Wrong !!");
                setisLoading(false);
            }
        } catch (error) {
            toast.error("Something Went Wrong !!");
            setisLoading(false);
        } finally {
            setisLoading(false);
        }
    };


    useEffect(() => {
        getSupportList();
    }, []);

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} />
            <Spinner loading={isLoading} />
            <div className="SupportContent">
                <div className="container">
                    {apiResponse?.data?.map((item) => {
                        if (item.question === question) {
                            return (
                                <>
                                    <h3 className="text-center py-3 m-0">{item.question}</h3>
                                    <div>
                                        <p className='px-3' style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.answer }}>
                                        </p>
                                    </div>
                                </>
                            )
                        } else {
                            return
                        }
                    })}
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default QuestionAnswer;
