import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';

function Terms() {
    const state = useSelector((state) => state);
    const navigate = useNavigate()
    const { counter_id } = useParams();

    const estateofmbsm = `<ol style="list-style-type: decimal; color: rgb(0, 0, 0); font-size: 11pt;">
    <li>
        <p>Entry shall only be allowed from the Badi Pol Gate located at Estate Of His Late Highness Maharana Bhagwat Singh Mewar, City Palace, Udaipur.</p>
    </li>
    <li>
        <p>Carrying and use of Professional Video Camera is strictly prohibited. The use of Tripods with any kind of Camera is also prohibited.</p>
    </li>
    <li>
        <p>Ticket sales are final and binding. This Ticket is non-transferable and non-refundable.</p>
    </li>
    <li>
        <p>For Adult: 18 years of age and above.</p>
    </li>
    <li>
        <p>For Child: from the age of 5 up to 18 years.</p>
    </li>
    <li>
        <p>Student must have valid ID Card of School/College for the current year.</p>
    </li>
    <li>
        <p>For Defence, Paramilitary, Police Personnel and their spouse valid Identity Card is required.</p>
    </li>
    <li>
        <p>For Senior Citizen above 60 years of age valid Identity Card is required.</p>
    </li>
    <li>
        <p>Liability, if any, shall be limited to the price of ticket only.</p>
    </li>
    <li>
        <p>The QR code above will be scanned by our Security check points at multiple locations while you are in The City Palace Premise.</p>
    </li>
    <li>
        <p>This is highly recommended to download your ticket in your mobile so that you can show it to security as and when required even if there is no internet connection.</p>
    </li>
    <li>
        <p>Kindly keep your one original photo ID Aadhar Card/Passport/Voter ID/PAN Card/Driving License; our security asks for the same to verify. The ID proof if not found verified/satisfactory then we are allowed to cancel it.</p>
    </li>
    <li>
        <p>Disputes are subject to Udaipur Jurisdiction only.</p>
    </li>
    <li>
        <p>The City Palace Museum closes at 9:00 pm. Entry is allowed in the Museum latest by 8:30 pm. The Museum is a no-smoking zone. Edibles and Inflammable/dangerous/explosive/weapons articles are strictly prohibited and the museum reserves the right to prohibit the entry of any articles/devices that are identified as dangerous in the sole discretion of the museum.</p>
    </li>
    <li>
        <p>The Museum intends the information contained in the Museum to be accurate and reliable. However, errors may occasionally occur. Therefore, all information and materials are provided "AS IS". In no event will the Museum be liable for any indirect, special, incidental, or consequential damages arising out of the use of the information contained in the Museum.</p>
    </li>
    <li>
        <p>Right of Admission Reserved -</p>
    </li>
    <li>
        <p>Please contact for any assistance at:</p>
        <p>The Administrator, Ticketing Department Badi Pol</p>
        <p>The City Palace, Estate Of His Late Highness Maharana Bhagwat Singh Mewar</p>
        <p>Udaipur 313001, Rajasthan, India</p>
        <p>Email: <a href="mailto:pranjaysingh.sisodiya@eternalmewar.in">pranjaysingh.sisodiya@eternalmewar.in</a>, <a href="mailto:cashier.mmcf@eternalmewar.in">cashier.mmcf@eternalmewar.in</a></p>
        <p>Contact Number: +91-9829007397, +91-998-268-3519</p>
    </li>
    <li>
        <p>Please inform within 48 hours in case of Payment Deduction but ticket not generated:</p>
        <p>Email: <a href="mailto:pranjaysingh.sisodiya@eternalmewar.in">pranjaysingh.sisodiya@eternalmewar.in</a>, <a href="mailto:cashier.mmcf@eternalmewar.in">cashier.mmcf@eternalmewar.in</a></p>
        <p>Contact Number: +91-9829007397, +91-998-268-3519</p>
    </li>
</ol>
`
    const hrhhotels = `
        <ol style="list-style-type: decimal; color: rgb(0, 0, 0); font-size: 11pt;">
            <li>
                <p>Entry shall only be allowed from the Badi Pol Gate located at Historic Resort Hotels Private Limited, City Palace, Udaipur.</p>
            </li>
            <li>
                <p>Carrying and use of Professional Video Camera is strictly prohibited. The use of Tripods with any kind of Camera is also prohibited.</p>
            </li>
            <li>
                <p>Ticket sales are final and binding. This Ticket is non-transferable and non-refundable.</p>
            </li>
            <li>
                <p>For Adult: 18 years of age and above.</p>
            </li>
            <li>
                <p>For Child: from the age of 5 up to 18 years.</p>
            </li>
            <li>
                <p>Student must have valid ID Card of School/College for the current year.</p>
            </li>
            <li>
                <p>For Defence, Paramilitary, Police Personnel and their spouse valid Identity Card is required.</p>
            </li>
            <li>
                <p>For Senior Citizen above 60 years of age valid Identity Card is required.</p>
            </li>
            <li>
                <p>Liability, if any, shall be limited to the price of ticket only.</p>
            </li>
            <li>
                <p>The QR code above will be scanned by our Security check points at multiple locations while you are in The City Palace Premise.</p>
            </li>
            <li>
                <p>This is highly recommended to download your ticket in your mobile so that you can show it to security as and when required even if there is no internet connection.</p>
            </li>
            <li>
                <p>Kindly keep your one original photo ID Aadhar Card/Passport/Voter ID/PAN Card/Driving License; our security asks for the same to verify. The ID proof, if not found verified/satisfactory, then we are allowed to cancel it.</p>
            </li>
            <li>
                <p>Disputes are subject to Udaipur Jurisdiction only.</p>
            </li>
            <li>
                <p>The City Palace Museum closes at 9:00 pm. Entry is allowed in the Museum latest by 8:30 pm. The Museum is a no-smoking zone. Edibles and Inflammable/dangerous/explosive/weapons articles are strictly prohibited and the museum reserves the right to prohibit the entry of any articles/devices that are identified as dangerous in the sole discretion of the museum.</p>
            </li>
            <li>
                <p>The Museum intends the information contained in the Museum to be accurate and reliable. However, errors may occasionally occur. Therefore, all information and materials are provided "AS IS". In no event will the Museum be liable for any indirect, special, incidental, or consequential damages arising out of the use of the information contained in the Museum.</p>
            </li>
            <li>
                <p>Right of Admission Reserved -</p>
            </li>
            <li>
                <p>Please contact for any assistance at:</p>
                <p>The Administrator, Ticketing Department Badi Pol</p>
                <p>The City Palace, Historic Resort Hotels Private Limited</p>
                <p>Udaipur 313001, Rajasthan, India</p>
                <p>Email: <a href="mailto:pranjaysingh.sisodiya@eternalmewar.in">pranjaysingh.sisodiya@eternalmewar.in</a>, <a href="mailto:cashier.mmcf@eternalmewar.in">cashier.mmcf@eternalmewar.in</a></p>
                <p>Contact Number: +91-9829007397, +91-998-268-3519</p>
            </li>
            <li>
            <p>Please inform within 48 hours in case of Payment Deduction but ticket not generated:</p>
            <p>Email: <a href="mailto:pranjaysingh.sisodiya@eternalmewar.in">pranjaysingh.sisodiya@eternalmewar.in</a>, <a href="mailto:cashier.mmcf@eternalmewar.in">cashier.mmcf@eternalmewar.in</a></p>
            <p>Contact Number: +91-9829007397, +91-998-268-3519</p>
            </li>
        </ol>

`
    const citypalacemuseum = `<ol style="list-style-type: decimal; color: rgb(0, 0, 0); font-size: 11pt;">
    <li>
        <p>Entry shall only be allowed from the Badi Pol Gate located at Maharana of Mewar Charitable Foundation, City Palace, Udaipur.</p>
    </li>
    <li>
        <p>Carrying and use of Professional Video Camera is strictly prohibited. The use of Tripods with any kind of Camera is also prohibited.</p>
    </li>
    <li>
        <p>Ticket sales are final and binding. This Ticket is non-transferable and non-refundable.</p>
    </li>
    <li>
        <p>For Adult: 18 years of age and above.</p>
    </li>
    <li>
        <p>For Child: from the age of 5 up to 18 years.</p>
    </li>
    <li>
        <p>Student must have valid ID Card of School/College for the current year.</p>
    </li>
    <li>
        <p>For Defence, Paramilitary, Police Personnel and their spouse valid Identity Card is required.</p>
    </li>
    <li>
        <p>For Senior Citizen above 60 years of age valid Identity Card is required.</p>
    </li>
    <li>
        <p>Liability, if any, shall be limited to the price of ticket only.</p>
    </li>
    <li>
        <p>The QR code above will be scanned by our Security check points at multiple locations while you are in The City Palace Premise.</p>
    </li>
    <li>
        <p>This is highly recommended to download your ticket in your mobile so that you can show it to security as and when required even if there is no internet connection.</p>
    </li>
    <li>
        <p>Kindly keep your one original photo ID Aadhar Card/Passport/Voter ID/PAN Card/Driving License; our security asks for the same to verify. The ID proof, if not found verified/satisfactory, then we are allowed to cancel it.</p>
    </li>
    <li>
        <p>Disputes are subject to Udaipur Jurisdiction only.</p>
    </li>
    <li>
        <p>The City Palace Museum closes at 9:00 pm. Entry is allowed in the Museum latest by 8:30 pm. The Museum is a no-smoking zone. Edibles and Inflammable/dangerous/explosive/weapons articles are strictly prohibited and the museum reserves the right to prohibit the entry of any articles/devices that are identified as dangerous in the sole discretion of the museum.</p>
    </li>
    <li>
        <p>The Museum intends the information contained in the Museum to be accurate and reliable. However, errors may occasionally occur. Therefore, all information and materials are provided "AS IS". In no event will the Museum be liable for any indirect, special, incidental, or consequential damages arising out of the use of the information contained in the Museum.</p>
    </li>
    <li>
        <p>Right of Admission Reserved -</p>
    </li>
    <li>
        <p>Please contact for any assistance at:</p>
        <p>The Administrator, Ticketing Department Badi Pol</p>
        <p>The City Palace, Maharana of Mewar Charitable Foundation</p>
        <p>Udaipur 313001, Rajasthan, India</p>
        <p>Email: <a href="mailto:pranjaysingh.sisodiya@eternalmewar.in">pranjaysingh.sisodiya@eternalmewar.in</a>, <a href="mailto:cashier.mmcf@eternalmewar.in">cashier.mmcf@eternalmewar.in</a></p>
        <p>Contact Number: +91-9829007397, +91-998-268-3519</p>
    </li>
    <li>
        <p>Please inform within 48 hours in case of Payment Deduction but ticket not generated:</p>
        <p>Email: <a href="mailto:pranjaysingh.sisodiya@eternalmewar.in">pranjaysingh.sisodiya@eternalmewar.in</a>, <a href="mailto:cashier.mmcf@eternalmewar.in">cashier.mmcf@eternalmewar.in</a></p>
        <p>Contact Number: +91-9829007397, +91-998-268-3519</p>
    </li>
</ol>
`

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} counterId={counter_id} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">Terms and Conditions</h3>
                    <div dangerouslySetInnerHTML={{ __html: estateofmbsm }} />
                    {/* {window.location.href.includes('eticket.estateofmbsm.org') &&
                        <></>
                    }
                    {window.location.href.includes('eticket.hrhhotels.com') &&
                        <div dangerouslySetInnerHTML={{ __html: hrhhotels }} />
                    }
                    {window.location.href.includes('eticket.citypalacemuseum.org') &&
                        <div dangerouslySetInnerHTML={{ __html: citypalacemuseum }} />
                    } */}
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default Terms;
