import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';

function PrivacyPolicy() {
    const state = useSelector((state) => state);
    const { counter_id } = useParams();
    const navigate = useNavigate()

    const estateofmbsm = `<h2>
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">Standard Terms of Use of our Website.</span>
</h2>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        Please read the whole of these standard terms of use of our Website carefully, because you are only permitted to make use of the content of this Website if you agree to them and, by using any content of this Website, you are deemed to agree to them.
    </span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        These standard terms of use are uniformly applicable throughout our Website; and in the event of any ambiguity with other terms and conditions found on our Website these standard terms of use shall always govern.
    </span>
</p>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">I. Definitions</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">In these Terms of Use:</span>
</p>
<ol>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Marks” means the trademarks or service marks, logos or brands for the time being and from time to time used or adopted by and belonging to us or a third party. The term “trademarks” shall be where the context admits to be deemed to include all logos and service marks.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Materials” means, to the extent it is protected copyright work, and is not expressly marked as “Third Party Property”, all or any part of any text, image, audio, video or any other type of media in any format contained and comprised in the Website. “Materials” do not include “Marks”.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Third Party Property” means all or any part of any Marks, text, image, audio, video or any other type of media in any format contained and comprised in the Website and expressly marked as the copyright property of a third party.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“We”, “our”, “us” means The Trustees of the Estate Of His Late Highness Maharana Bhagwat Singh Mewar or as the context requires; the City Palace, Udaipur.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Website” means our website at www.citypalacemuseum.org &amp; www.eternalmewar.in/ and any sub-domains.</span>
        </p>
    </li>
</ol>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">II. Marks</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        Nothing contained in the Website shall be construed as granting any license or right to use of our Marks or those comprised in any Third Party Property displayed on our Website without our prior written permission or that of any third party owner. Use and misuse of the Marks on our Website, for any purpose what-so-ever, is strictly prohibited.
    </span>
</p>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">III. Disclaimer</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        We have taken every effort and care in preparing Materials, however we disclaim all warranties, express or implied, as to the accuracy thereof, and Materials shall at all times constitute work in progress which we may change without notice. We shall not be liable for any loss or damage arising from your use of or reliance on all Materials on our website.
    </span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        Links within our Website may lead to other websites. They are provided for convenience only. We do not sponsor, nor necessarily endorse or otherwise approve of any information or statements appearing in those websites (websites referred to or linked to).
    </span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        Every reasonable effort has been made to seek appropriate permission from people identifiable in photographs used throughout the site.
    </span>
</p>
<h2>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">Privacy</span>
    
</h2>
<ol>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">
                We are committed to protecting the privacy and the confidentiality of personal information of visitors to our website. We undertake to ensure that all personal information in our possession is processed in accordance with the principles of the Data Protection Act 1998.
            </span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">
                We collect personal information (such as your name, contact details, credit card information) that you supply to us. Your information is collected when you request information from us, contact us, make a booking with us, purchase items from us, including Membership and e-tickets. We will update your information whenever we get the opportunity to keep it current, accurate and complete.
            </span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">
                Any information you provide when registering with us, buying Membership, e-tickets and other products will be used for Estate Of His Late Highness Maharana Bhagwat Singh Mewar and purposes only. From time to time we may send you information from other organizations which we think may be of interest to you. We may also disclose your information to companies who act as "data processors" on our behalf, some of whom may be outside India.
            </span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">
                You may indicate your preference for receiving direct marketing by telephone or email from us or any partner organizations with whom we may work. You will be given the opportunity on every communication we send you to indicate that you no longer wish to receive our direct marketing material. You may, in addition, indicate your preference regarding receiving third party direct marketing material. Once properly notified by you, we will take steps to stop using your information.
            </span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">
                The credit card information you give for any online transaction is used solely for the purpose of processing that particular transaction. Since it is technologically possible for unauthorized third parties to intercept any email or any message posted on an insecure web page, we strongly advise you against sending us any credit card information via email or an insecure web page. Secure web pages begin “https”. Moreover it is contrary to the data security rules of the payment card industry for us to accept credit card payments by email and we would decline to accept payment by these means.
            </span>
        </p>
    </li>
</ol>
<ol start="6">
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">
                You have the right to ask in writing for a copy of the information we hold about you and to correct any inaccuracies in your information (for which we may charge an administrative fee).
            </span>
        </p>
    </li>
</ol>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Use of &apos;cookies&apos;</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">Cookies and the Estate Of His Late Highness Maharana Bhagwat Singh Mewar website</span></p>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Consequential loss</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">Except to the extent required by the legal jurisdiction of State of Rajasthan, India, in no circumstances will we be liable to you, in law, for any special, incidental, consequential, punitive or exemplary damages or wasted costs arising from your use of Materials, even if we have been advised of the possibility of such damages.</span></p>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Applicable law</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">You agree that the terms and conditions of your use of our Website shall be governed by and construed in accordance with, the legal jurisdiction of State of Rajasthan, India and you agree that the courts of State of Rajasthan, India shall have exclusive jurisdiction in relation to any dispute or matter arising in connection with these terms and conditions and your use of this Website.</span></p>`

    const hrhhotels = `<h2>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">Standard Terms of Use of our Website.</span>
    
</h2>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        Please read the whole of these standard terms of use of our Website carefully, because you are only permitted to make use of the content of this Website if you agree to them and, by using any content of this Website, you are deemed to agree to them.
    </span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        These standard terms of use are uniformly applicable throughout our Website; and in the event of any ambiguity with other terms and conditions found on our Website these standard terms of use shall always govern.
    </span>
</p>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 13pt;">I. Definitions</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">In these Terms of Use:</span>
</p>
<ol>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Marks” means the trademarks or service marks, logos or brands for the time being and from time to time used or adopted by and belonging to us or a third party. The term “trademarks” shall be where the context admits to be deemed to include all logos and service marks.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Materials” means, to the extent it is protected copyright work, and is not expressly marked as “Third Party Property”, all or any part of any text, image, audio, video or any other type of media in any format contained and comprised in the Website. “Materials” do not include “Marks”.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Third Party Property” means all or any part of any Marks, text, image, audio, video or any other type of media in any format contained and comprised in the Website and expressly marked as the copyright property of a third party.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“We”, “our”, “us” means The Trustees of the Historic Resort Hotels Private Limited or as the context requires; the City Palace, Udaipur.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Website” means our website at www.citypalacemuseum.org &amp; www.eternalmewar.in/ and any sub-domains.</span>
        </p>
    </li>
</ol>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 13pt;">II. Marks</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">Nothing contained in the Website shall be construed as granting any license or right to use of our Marks or those comprised in any Third Party Property displayed on our Website without our prior written permission or that of any third party owner. Use and misuse of the Marks on our Website, for any purpose what-so-ever, is strictly prohibited.</span>
</p>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 13pt;">III. Disclaimer</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">We have taken every effort and care in preparing Materials, however we disclaim all warranties, express or implied, as to the accuracy thereof, and Materials shall at all times constitute work in progress which we may change without notice. We shall not be liable for any loss or damage arising from your use of or reliance on all Materials on our website.</span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">Links within our Website may lead to other websites. They are provided for convenience only. We do not sponsor, nor necessarily endorse or otherwise approve of any information or statements appearing in those websites (websites referred to or linked to).</span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">Every reasonable effort has been made to seek appropriate permission from people identifiable in photographs used throughout the site.</span>
</p>
<h2>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">Privacy</span>
    
</h2>
<ol>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">We are committed to protecting the privacy and the confidentiality of personal information of visitors to our website. We undertake to ensure that all personal information in our possession is processed in accordance with the principles of the Data Protection Act 1998.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">We collect personal information (such as your name, contact details, credit card information) that you supply to us. Your information is collected when you request information from us, contact us, make a booking with us, purchase items from us, including Membership and e-tickets. We will update your information whenever we get the opportunity to keep it current, accurate and complete.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">Any information you provide when registering with us, buying Membership, e-tickets and other products will be used for Historic Resort Hotels Private Limited and purposes only. From time to time we may send you information from other organizations which we think may be of interest to you. We may also disclose your information to companies who act as "data processors" on our behalf, some of whom may be outside India.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">You may indicate your preference for receiving direct marketing by telephone or email from us or any partner organizations with whom we may work. You will be given the opportunity on every communication we send you to indicate that you no longer wish to receive our direct marketing material. You may, in addition, indicate your preference regarding receiving third party direct marketing material. Once properly notified by you, we will take steps to stop using your information.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">The credit card information you give for any online transaction is used solely for the purpose of processing that particular transaction. Since it is technologically possible for unauthorized third parties to intercept any email or any message posted on an insecure web page, we strongly advise you against sending us any credit card information via email or an insecure web page. Secure web pages begin with “https”. Moreover it is contrary to the data security rules of the payment card industry for us to accept credit card payments by email and we would decline to accept payment by these means.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">You have the right to ask in writing for a copy of the information we hold about you and to correct any inaccuracies in your information (for which we may charge an administrative fee).</span>
        </p>
    </li>
</ol>

<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Use of &apos;cookies&apos;</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">Cookies and the Historic Resort Hotels Private Limited website</span></p>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Consequential loss</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">Except to the extent required by the legal jurisdiction of State of Rajasthan, India, in no circumstances will we be liable to you, in law, for any special, incidental, consequential, punitive or exemplary damages or wasted costs arising from your use of Materials, even if we have been advised of the possibility of such damages.</span></p>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Applicable law</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">You agree that the terms and conditions of your use of our Website shall be governed by and construed in accordance with, the legal jurisdiction of State of Rajasthan, India and you agree that the courts of State of Rajasthan, India shall have exclusive jurisdiction in relation to any dispute or matter arising in connection with these terms and conditions and your use of this Website.</span></p>`

    const citypalacemuseum = `<h2>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">Standard Terms of Use of our Website.</span>
    
</h2>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        Please read the whole of these standard terms of use of our Website carefully, because you are only permitted to make use of the content of this Website if you agree to them and, by using any content of this Website, you are deemed to agree to them.
    </span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        These standard terms of use are uniformly applicable throughout our Website; and in the event of any ambiguity with other terms and conditions found on our Website these standard terms of use shall always govern.
    </span>
</p>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 13pt;">I. Definitions</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">In these Terms of Use:</span>
</p>
<ol>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Marks” means the trademarks or service marks, logos or brands for the time being and from time to time used or adopted by and belonging to us or a third party. The term “trademarks” shall be where the context admits to be deemed to include all logos and service marks.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Materials” means, to the extent it is protected copyright work, and is not expressly marked as “Third Party Property”, all or any part of any text, image, audio, video or any other type of media in any format contained and comprised in the Website. “Materials” do not include “Marks”.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Third Party Property” means all or any part of any Marks, text, image, audio, video or any other type of media in any format contained and comprised in the Website and expressly marked as the copyright property of a third party.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“We”, “our”, “us” means The Trustees of the Maharana of Mewar Charitable Foundation or as the context requires; the City Palace, Udaipur.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">“Website” means our website at www.citypalacemuseum.org &amp; www.eternalmewar.in/ and any sub-domains.</span>
        </p>
    </li>
</ol>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 13pt;">II. Marks</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">Nothing contained in the Website shall be construed as granting any license or right to use of our Marks or those comprised in any Third Party Property displayed on our Website without our prior written permission or that of any third party owner. Use and misuse of the Marks on our Website, for any purpose what-so-ever, is strictly prohibited.</span>
</p>
<h3>
    
        <span style="color: rgb(0, 0, 0);font-size: 13pt;">III. Disclaimer</span>
    
</h3>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">We have taken every effort and care in preparing Materials, however we disclaim all warranties, express or implied, as to the accuracy thereof, and Materials shall at all times constitute work in progress which we may change without notice. We shall not be liable for any loss or damage arising from your use of or reliance on all Materials on our website.</span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">Links within our Website may lead to other websites. They are provided for convenience only. We do not sponsor, nor necessarily endorse or otherwise approve of any information or statements appearing in those websites (websites referred to or linked to).</span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">Every reasonable effort has been made to seek appropriate permission from people identifiable in photographs used throughout the site.</span>
</p>
<h2>
    
        <span style="color: rgb(0, 0, 0);font-size: 14pt;">Privacy</span>
    
</h2>
<ol>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">We are committed to protecting the privacy and the confidentiality of personal information of visitors to our website. We undertake to ensure that all personal information in our possession is processed in accordance with the principles of the Data Protection Act 1998.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">We collect personal information (such as your name, contact details, credit card information) that you supply to us. Your information is collected when you request information from us, contact us, make a booking with us, purchase items from us, including Membership and e-tickets. We will update your information whenever we get the opportunity to keep it current, accurate and complete.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">Any information you provide when registering with us, buying Membership, e-tickets and other products will be used for Maharana of Mewar Charitable Foundation and purposes only. From time to time we may send you information from other organizations which we think may be of interest to you. We may also disclose your information to companies who act as "data processors" on our behalf, some of whom may be outside India.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">You may indicate your preference for receiving direct marketing by telephone or email from us or any partner organizations with whom we may work. You will be given the opportunity on every communication we send you to indicate that you no longer wish to receive our direct marketing material. You may, in addition, indicate your preference regarding receiving third party direct marketing material. Once properly notified by you, we will take steps to stop using your information.</span>
        </p>
    </li>
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">The credit card information you give for any online transaction is used solely for the purpose of processing that particular transaction. Since it is technologically possible for unauthorized third parties to intercept any email or any message posted on an insecure web page, we strongly advise you against sending us any credit card information via email or an insecure web page. Secure web pages begin with “https”. Moreover it is contrary to the data security rules of the payment card industry for us to accept credit card payments by email and we would decline to accept payment by these means.</span>
        </p>
    </li>
</ol>
<ol start="6">
    <li style="list-style-type: decimal;color: rgb(0, 0, 0);font-size: 11pt;">
        <p>
            <span style="color: rgb(0, 0, 0);font-size: 11pt;">You have the right to ask in writing for a copy of the information we hold about you and to correct any inaccuracies in your information (for which we may charge an administrative fee).</span>
        </p>
    </li>
</ol>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Use of &apos;cookies&apos;</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">Cookies and the Maharana of Mewar Charitable Foundation website</span></p>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Consequential loss</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">Except to the extent required by the legal jurisdiction of State of Rajasthan, India, in no circumstances will we be liable to you, in law, for any special, incidental, consequential, punitive or exemplary damages or wasted costs arising from your use of Materials, even if we have been advised of the possibility of such damages.</span></p>
<h2><span style="color: rgb(0, 0, 0);font-size: 14pt;">Applicable law</span></h2>
<p><span style="color: rgb(0, 0, 0);font-size: 11pt;">You agree that the terms and conditions of your use of our Website shall be governed by and construed in accordance with, the legal jurisdiction of State of Rajasthan, India and you agree that the courts of State of Rajasthan, India shall have exclusive jurisdiction in relation to any dispute or matter arising in connection with these terms and conditions and your use of this Website.</span></p>`

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} counterId={counter_id} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">Privacy Policy</h3>
                    <div dangerouslySetInnerHTML={{ __html: estateofmbsm }} />
                    {/* {window.location.href.includes('eticket.estateofmbsm.org') &&
                        <></>
                    }
                    {window.location.href.includes('eticket.hrhhotels.com') &&
                        <div dangerouslySetInnerHTML={{ __html: hrhhotels }} />
                    }
                    {window.location.href.includes('eticket.citypalacemuseum.org') &&
                        <div dangerouslySetInnerHTML={{ __html: citypalacemuseum }} />
                    } */}
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default PrivacyPolicy;
