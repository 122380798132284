import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

//SVG
import { getCurrentLocation } from '../global/Function';
import { APIENDPOINT } from '../global/Const';
import { postData } from '../global/Api';
import { setData } from '../redux/actionTypes/rootAction';
import Spinner from '../components/common/Spinner';
import Button from '../components/common/Button';
import Header from '../components/common/Header';
import '../css/main.css';

function Place() {
    const { counter_id, sightseen_id } = useParams();
    const [apiResponse, setapiResponse] = useState([])
    const [subLogo, setsubLogo] = useState('')
    const [isLoading, setisLoading] = useState(true)
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        getplacebyCounter()
    }, [])

    async function getplacebyCounter() {
        const { latitude, longitude } = await getCurrentLocation()
        const formDat = {
            "counter_slug": counter_id.toString(),
            "sightseeing_id": sightseen_id.toString(),
            "user_lat": latitude.toString(),
            "user_long": longitude.toString()
        }
        const resposeApi = await postData(APIENDPOINT.GELALLPRODUCT, formDat)
        if (resposeApi.response_code === 200 && resposeApi.status === true) {
            setapiResponse(resposeApi.data)
            setsubLogo(resposeApi.data.logo)
            setisLoading(false)
        } else if (resposeApi.message) {
            toast.error(resposeApi.message)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    }

    const handleonClick = (item) => {
        const data = {
            ...state,
            sub_logo: subLogo,
            product_id: item.id,
            product_name: item.name,
            product_validity: item.validity
        }
        dispatch(setData(data))
    }
    return (
        <div className="App">
            <Header sublogo={subLogo} mainlogo={state.main_logo} style={3} counterId={counter_id} />
            <Spinner loading={isLoading} />
            <div className="PlaceContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">{state?.sightseeing_name}</h3>
                    {apiResponse?.list ?
                        <>
                            <div>
                                {apiResponse?.list?.map((item, index) => {
                                    return (
                                        <div>
                                            <div className="row py-2 p-0" key={index}>
                                                <div className="col-7">
                                                    <h6 className="mx-0">{item.name}</h6>
                                                    <h6 style={{ color: "#B47137" }}>{item.validity}</h6>
                                                </div>
                                                <div className="col-5" style={{ alignContent: 'center' }}>
                                                    <Button text={'Buy Ticket'} width={100} color={"#BC6122"} onClick={() => handleonClick(item)} href={process.env.PUBLIC_URL + `/counter/${counter_id}/${sightseen_id}/${item.id}`} />
                                                </div>
                                                <label className='m-0 fs-13 text-danger'>Booking Time : {item.booking_time}</label>
                                            </div>
                                            <hr className='m-0' />
                                        </div>
                                    )
                                })}
                            </div>
                            {apiResponse.photo &&
                                <div className='py-4'>
                                    <img src={apiResponse.photo} alt="place-logo" style={{ width: "100%", maxHeight: 220 }} />
                                    <p style={{ textAlign: 'justify' }} className='pt-3'>{apiResponse.description}</p>
                                </div>
                            }
                        </> :
                        !isLoading && <p className="fs-6 text-center py-5 text-danger">No Tickets Available !!</p>
                    }
                </div>
            </div>
            <footer className="Footer" >
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className='FooterCompoenent'>
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default Place;
