import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { postData } from "../global/Api";
import { APIENDPOINT } from "../global/Const";
import { setData } from "../redux/actionTypes/rootAction";
import { getCurrentLocation } from "../global/Function";
import Spinner from "../components/common/Spinner";
import Header from "../components/common/Header";
import '../css/main.css';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

function Booking() {
    const { counter_id, sightseen_id, product_id } = useParams();
    const [isLoading, setisLoading] = useState(true);
    const [isErrorModal, setisErrorModal] = useState(false);
    const [apiResponse, setapiResponse] = useState({});
    const [ticketItems, setTicketItems] = useState([]);
    const [terms, setTerms] = useState('');
    const [termsHindi, setTermsHindi] = useState('');
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [maxTicket, setmaxTicket] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getPlaceByCounter();
    }, []);

    useEffect(() => {
        if (errors.apiresponse) {
            setisErrorModal(true)
        }
    }, [errors.apiresponse])

    useEffect(() => {
        if (apiResponse && apiResponse.list) {
            const initialTicketItems = apiResponse.list.map(item => {
                const matchingSaleHeadItem = state.sale_head.find(h_item => h_item.id === item.id);
                return {
                    id: item.id,
                    type: item.name,
                    no_of_tickets: matchingSaleHeadItem ? matchingSaleHeadItem.no_of_tickets : 0,
                    gst: item.gst,
                    price: item.price,
                    terms_conditions: item.terms_conditions,
                    is_value_added_process: item.is_value_added_process,
                    display_order: matchingSaleHeadItem ? matchingSaleHeadItem.display_order : 0,
                };
            });
            setmaxTicket(parseInt(apiResponse.max_pax_allowed))
            setTicketItems(initialTicketItems);
        }
    }, [apiResponse, state.sale_head]);

    async function getPlaceByCounter() {
        let error = {};
        try {
            const { latitude, longitude } = await getCurrentLocation();
            const formData = {
                "counter_slug": counter_id.toString(),
                "product_id": product_id.toString(),
                "user_lat": latitude.toString(),
                "user_long": longitude.toString()
            };
            const responseApi = await postData(APIENDPOINT.GETALLSALESPRODUCT, formData);
            if (responseApi.response_code === 200 && responseApi.status === true) {
                const data = {
                    ...state,
                    user_lat: latitude.toString(),
                    user_long: longitude.toString(),
                };
                dispatch(setData(data));
                setapiResponse(responseApi.data);
                setTerms(responseApi.data.other_terms_conditions);
                setTermsHindi(responseApi.data.other_terms_conditions_hindi);
                setisLoading(false);
            } else if (responseApi.message) {
                error.apiresponse = responseApi.message
                setErrors(error);
                // toast.error(responseApi.message);
                setisLoading(false);
            } else {
                toast.error("Something Went Wrong !!");
                setisLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Error fetching data. Please try again.");
            setisLoading(false);
        }
    }

    // const handleIncrement = (type) => {
    //     setTicketItems(ticketItems.map(item =>
    //         item.type === type ? { ...item, no_of_tickets: item.no_of_tickets + 1 } : item
    //     ));
    // };

    const handleIncrement = (type) => {
        if (maxTicket > 0) {
            const totalTickets = ticketItems.reduce((sum, item) => sum + item.no_of_tickets, 0);
            const totalDisplayOrder = ticketItems.filter((item) => item.display_order != 0);
            if (totalTickets < maxTicket) {
                setTicketItems(ticketItems.map(t =>
                    t.type === type ? { ...t, no_of_tickets: t.no_of_tickets + 1, display_order: t.display_order == 0 ? totalDisplayOrder.length + 1 : t.display_order } : t
                ));
            } else {
                toast.warn(`You can book a maximum of ${maxTicket} tickets.`);
            }
        } else {
            setTicketItems(ticketItems.map(t =>
                t.type === type ? { ...t, no_of_tickets: t.no_of_tickets + 1 } : t
            ));
        }
    };

    const handleDecrement = (type) => {
        setTicketItems(ticketItems.map(item =>
            item.type === type && item.no_of_tickets > 0 ? { ...item, no_of_tickets: item.no_of_tickets - 1 } : item
        ));
    };

    const calculateTotal = () => {
        return ticketItems.reduce((total, item) => total + item.no_of_tickets * item.price, 0);
    };

    const calculateGST = () => {
        const totalGST = ticketItems.reduce((totalGST, item) => {
            const itemGST = (item.price * (item.gst || 0)) / 100;
            return totalGST + item.no_of_tickets * itemGST;
        }, 0);

        return parseFloat(totalGST.toFixed(2));
    };


    // const roundAmount = (amount) => {
    //     const roundedAmount = parseFloat(amount).toFixed(2);
    //     const decimalPart = parseFloat(roundedAmount - Math.floor(roundedAmount)).toFixed(2);

    //     if (decimalPart <= 0.5) {
    //         return -Math.abs(parseFloat(decimalPart).toFixed(2));
    //     } else {
    //         return Math.abs(parseFloat(1 - decimalPart).toFixed(2));
    //     }
    // };

    const roundAmount = () => {
        const total = parseFloat(calculateTotal()).toFixed(2)
        const cgst = parseFloat(calculateGST() / 2).toFixed(2)
        const sgst = parseFloat(calculateGST() / 2).toFixed(2)
        const amount = parseFloat(total) + parseFloat(cgst) + parseFloat(sgst)
        const roundedAmount = parseFloat(amount).toFixed(2);
        const decimalPart = parseFloat(roundedAmount - Math.floor(roundedAmount)).toFixed(2);

        if (decimalPart <= 0.5) {
            return -Math.abs(parseFloat(decimalPart).toFixed(2));
        } else {
            return Math.abs(parseFloat(1 - decimalPart).toFixed(2));
        }
    };


    const calculateGrandTotal = () => {
        const total = parseFloat(calculateTotal()).toFixed(2)
        const cgst = parseFloat(calculateGST() / 2).toFixed(2)
        const sgst = parseFloat(calculateGST() / 2).toFixed(2)
        const amount = parseFloat(total) + parseFloat(cgst) + parseFloat(sgst)
        const roundOff = roundAmount();
        const grandTotal = amount + roundOff;
        return grandTotal.toFixed(2);
    };

    const handleOnClick = () => {
        const selectedTickets = ticketItems.filter(item => item.no_of_tickets > 0);
        const totalTickets = selectedTickets.reduce((sum, item) => sum + item.no_of_tickets, 0);
        const isnotonlyValueadded = ticketItems.filter(item => !item.is_value_added_process && item.no_of_tickets > 0)

        if (isnotonlyValueadded.length > 0 && selectedTickets.length > 0) {
            if (totalTickets <= maxTicket) {
                const data = {
                    ...state,
                    sale_head: selectedTickets
                };
                dispatch(setData(data));
                navigate(`/counter/${counter_id}/${sightseen_id}/${product_id}/buyer-detail`);
            } else {
                toast.warn(`You can book a maximum of ${maxTicket} tickets.`);
            }
        } else {
            toast.warn("Please select at least one ticket to proceed.");
        }
    };

    function isValidPrice(price) {
        return price !== null &&
            price !== undefined &&
            price !== 0 &&
            price !== "0" &&
            price !== "0.00";
    }

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={3} counterId={counter_id} />
            <Spinner loading={isLoading} />
            <div className="BookingContainer">
                <div className="container py-3">

                    <Modal
                        isOpen={isErrorModal}
                        backdrop={'static'}
                        centered
                        keyboard={false}
                    >
                        <ModalHeader style={{ border: 'none' }}>Info</ModalHeader>
                        <ModalBody>
                            <p className="m-0" style={{ fontSize: 16, fontWeight: '500' }}>{errors.apiresponse}</p>
                        </ModalBody>

                        <Button className="mb-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'inherit', width: 60, fontWeight: '600', alignSelf: 'flex-end' }} onClick={() => navigate(-1)}>
                            OK
                        </Button>
                    </Modal>

                    <h3 className="text-center m-0">{state.product_name}</h3>
                    <h6 className="text-center m-0 mb-2" style={{ color: "#B47137" }}>{state.product_validity}</h6>
                    {ticketItems.length ?
                        <div className="col-lg-12">
                            <div className="table-responsive rounded">
                                <table className="table table-centered mb-0 table-nowrap table-borderless">
                                    <thead style={{ borderBottomWidth: 1, borderColor: '#d6d6d6' }}>
                                        <tr>
                                            <th className="col-2 fs-14" style={{ fontWeight: 'normal', textAlign: 'center' }}></th>
                                            <th className="col-3 fs-14" style={{ fontWeight: 'normal', textAlign: 'center' }}>Rate</th>
                                            <th className="col-3 fs-14" style={{ fontWeight: 'normal', textAlign: 'center' }}>Select Ticket</th>
                                            <th className="col-4 fs-14" style={{ fontWeight: 'normal', textAlign: 'right' }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ticketItems.map((item, index) => {
                                            if (!item.is_value_added_process) {
                                                return (
                                                    <>
                                                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                            <td >
                                                                <span className="fs-14">{item.type}</span>
                                                            </td>
                                                            <td className="text-center fs-14" >{parseFloat(item.price).toFixed(2)}</td>
                                                            <td className="text-center fs-14">
                                                                <div style={{ width: '120px' }} className="d-inline-flex justify-content-center">
                                                                    <div className="input-group" style={{ backgroundColor: "#EDDACC", borderRadius: 10 }}>
                                                                        <span className="input-group-btn">
                                                                            <button style={{ backgroundColor: '#F6A468', zIndex: 'auto' }} className="btn" type="button" onClick={() => handleDecrement(item.type)}>-</button>
                                                                        </span>
                                                                        <input
                                                                            onBlur={() => {
                                                                                const totalTickets = ticketItems.reduce((sum, item) => sum + item.no_of_tickets, 0);
                                                                                if (totalTickets <= maxTicket) {
                                                                                    console.log("Continue")
                                                                                } else {
                                                                                    toast.warn(`You can book a maximum of ${maxTicket} tickets.`);
                                                                                }
                                                                            }}
                                                                            style={{ backgroundColor: '#EDDACC' }}
                                                                            type="text" value={item.no_of_tickets}
                                                                            className="form-control text-center"
                                                                            // onChange={(e) => {
                                                                            //     const input = e.target.value;
                                                                            //     const filteredInput = input.replace(/\D/g, '');
                                                                            //     const updatedItems = ticketItems.map(ticket =>
                                                                            //         ticket.type === item.type
                                                                            //             ? { ...ticket, no_of_tickets: parseInt(filteredInput) || 0 }
                                                                            //             : ticket
                                                                            //     );
                                                                            //     setTicketItems(updatedItems);
                                                                            // }} 
                                                                            onChange={(e) => {
                                                                                const input = e.target.value;
                                                                                const filteredInput = input.replace(/\D/g, '');
                                                                                const updatedItems = ticketItems.map(ticket => {
                                                                                    if (ticket.type === item.type) {
                                                                                        const newNoOfTickets = parseInt(filteredInput) || 0;
                                                                                        let newDisplayOrder = ticket.display_order;

                                                                                        if (newNoOfTickets === 0) {
                                                                                            // Handle case where ticket count is set to zero
                                                                                            newDisplayOrder = 0;
                                                                                        } else if (ticket.display_order === 0) {
                                                                                            // Calculate new display order if it was previously zero
                                                                                            const totalDisplayOrder = ticketItems.filter(t => t.display_order !== 0);
                                                                                            newDisplayOrder = totalDisplayOrder.length + 1;
                                                                                        }

                                                                                        return { ...ticket, no_of_tickets: newNoOfTickets, display_order: newDisplayOrder };
                                                                                    }
                                                                                    return ticket;
                                                                                });
                                                                                setTicketItems(updatedItems);
                                                                            }}
                                                                        />
                                                                        <span className="input-group-btn input-group-append">
                                                                            <button style={{ backgroundColor: '#F6A468', zIndex: 'auto' }} className="btn" type="button" onClick={() => handleIncrement(item.type)}>+</button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end fs-14" >{parseFloat(item.no_of_tickets * item.price).toFixed(2)}</td>
                                                        </tr>
                                                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                            <td className="text-danger ps-2" style={{ fontSize: 12, borderBottomWidth: 1, paddingBottom: 5, borderColor: '#d6d6d6' }} colSpan={4}>
                                                                {item?.terms_conditions && (item.terms_conditions.includes('\n') || item.terms_conditions.includes('\\n'))
                                                                    ? item.terms_conditions.split(/\\n|\n/).map((line, lineIndex) => (
                                                                        <div key={lineIndex}>{line}</div>
                                                                    ))
                                                                    : item?.terms_conditions}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        })}
                                        {ticketItems.find(itemAdded => itemAdded.is_value_added_process) &&
                                            <tr>
                                                <td className="px-0 py-2" style={{ fontSize: 15, fontWeight: 'bold' }} colSpan={4}>Value Added Services</td>
                                            </tr>
                                        }
                                        {ticketItems.map((itemAdded, index) => {
                                            if (itemAdded.is_value_added_process) {
                                                return (
                                                    <>
                                                        <tr key={index} className="bg-custom-color-tabel" style={{ borderTopWidth: 1, borderColor: '#d6d6d6', verticalAlign: 'middle' }}>
                                                            <td >
                                                                <span className="fs-14">{itemAdded.type}</span>
                                                            </td>
                                                            <td className="text-center fs-14" >{parseFloat(itemAdded.price).toFixed(2)}</td>
                                                            <td className="text-center fs-14 p-0">
                                                                {isValidPrice(itemAdded.price) &&
                                                                    <div style={{ width: '120px' }} className="d-inline-flex justify-content-center">
                                                                        <div className="input-group" style={{ backgroundColor: "#EDDACC", borderRadius: 10 }}>
                                                                            <span className="input-group-btn">
                                                                                <button disabled={!isValidPrice(itemAdded.price)} style={{ backgroundColor: '#F6A468', zIndex: 'auto' }} className="btn" type="button" onClick={() => handleDecrement(itemAdded.type)}>-</button>
                                                                            </span>
                                                                            <input
                                                                                onBlur={() => {
                                                                                    const totalTickets = ticketItems.reduce((sum, item) => sum + item.no_of_tickets, 0);
                                                                                    if (totalTickets <= maxTicket) {
                                                                                        console.log("Continue")
                                                                                    } else {
                                                                                        toast.warn(`You can book a maximum of ${maxTicket} tickets.`);
                                                                                    }
                                                                                }}
                                                                                disabled={!isValidPrice(itemAdded.price)}
                                                                                style={{ backgroundColor: '#EDDACC' }}
                                                                                type="text" value={itemAdded.no_of_tickets}
                                                                                className="form-control text-center"
                                                                                // onChange={(e) => {
                                                                                //     const input = e.target.value;
                                                                                //     const filteredInput = input.replace(/\D/g, '');
                                                                                //     const updatedItems = ticketItems.map(ticket =>
                                                                                //         ticket.type === itemAdded.type
                                                                                //             ? { ...ticket, no_of_tickets: parseInt(filteredInput) || 0 }
                                                                                //             : ticket
                                                                                //     );
                                                                                //     setTicketItems(updatedItems);
                                                                                // }} 

                                                                                onChange={(e) => {
                                                                                    const input = e.target.value;
                                                                                    const filteredInput = input.replace(/\D/g, '');
                                                                                    const updatedItems = ticketItems.map(ticket => {
                                                                                        if (ticket.type === itemAdded.type) {
                                                                                            const newNoOfTickets = parseInt(filteredInput) || 0;
                                                                                            let newDisplayOrder = ticket.display_order;

                                                                                            if (newNoOfTickets === 0) {
                                                                                                // Handle case where ticket count is set to zero
                                                                                                newDisplayOrder = 0;
                                                                                            } else if (ticket.display_order === 0) {
                                                                                                // Calculate new display order if it was previously zero
                                                                                                const totalDisplayOrder = ticketItems.filter(t => t.display_order !== 0);
                                                                                                newDisplayOrder = totalDisplayOrder.length + 1;
                                                                                            }

                                                                                            return { ...ticket, no_of_tickets: newNoOfTickets, display_order: newDisplayOrder };
                                                                                        }
                                                                                        return ticket;
                                                                                    });
                                                                                    setTicketItems(updatedItems);
                                                                                }}
                                                                            />
                                                                            <span className="input-group-btn input-group-append">
                                                                                <button disabled={!isValidPrice(itemAdded.price)} style={{ backgroundColor: '#F6A468', zIndex: 'auto' }} className="btn" type="button" onClick={() => handleIncrement(itemAdded.type)}>+</button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="text-end fs-14" >{parseFloat(itemAdded.no_of_tickets * itemAdded.price).toFixed(2)}</td>
                                                        </tr>
                                                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                            <td className="text-danger ps-2" style={{ fontSize: 12, borderBottomWidth: 1, paddingBottom: 5, borderColor: '#d6d6d6' }} colSpan={4}>
                                                                {itemAdded?.terms_conditions && (itemAdded.terms_conditions.includes('\n') || itemAdded.terms_conditions.includes('\\n'))
                                                                    ? itemAdded.terms_conditions.split(/\\n|\n/).map((line, lineIndex) => (
                                                                        <div key={lineIndex}>{line}</div>
                                                                    ))
                                                                    : itemAdded?.terms_conditions}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        })}
                                        <tr style={{ borderTopWidth: 1, borderColor: '#d6d6d6' }} className="text-end">
                                            <td className="py-1 fs-14" colSpan="3">Sub Total</td>
                                            <td className="py-1 fs-14">{parseFloat(calculateTotal()).toFixed(2)}</td>
                                        </tr>
                                        {calculateGST() !== 0 &&
                                            <>
                                                <tr className="text-end">
                                                    <td className="py-1 fs-14" colSpan="3">CGST ({ticketItems[0]?.gst / 2}%)</td>
                                                    {console.log(calculateGST() / 2)}
                                                    <td className="py-1 fs-14">{parseFloat(calculateGST() / 2).toFixed(2)}</td>
                                                </tr>
                                                <tr className="text-end">
                                                    <td className="py-1 fs-14" colSpan="3">SGST ({ticketItems[0]?.gst / 2}%)</td>
                                                    <td className="py-1 fs-14">{parseFloat(calculateGST() / 2).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        }
                                        {roundAmount() != 0 &&
                                            <tr className="text-end">
                                                <td className="py-1 fs-14" colSpan="3">Round off</td>
                                                <td className="py-1 fs-14">{roundAmount()}</td>
                                            </tr>
                                        }
                                        <tr style={{ borderBottomWidth: 1, borderColor: '#d6d6d6' }} className="text-end">
                                            <td className="py-1 fs-14 fw-bold" colSpan="3">Grand Total</td>
                                            <td className="py-1 fs-14 fw-bold">{calculateGrandTotal()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {apiResponse?.invoice_type &&
                                <p className="ps-2 text-end m-0 fw-bold" style={{ fontSize: 10 }} colSpan={4}>Invoice Type : {apiResponse?.invoice_type}</p>
                            }
                            <div className="TermsConditions">
                                <h5>Terms & Conditions :</h5>
                                {/* <ol className="px-3 m-0">
                                    {ticketItems.map((item, index) => (
                                        item.terms_conditions.trim() && <li key={index}>{item.terms_conditions.trim()}</li>
                                    ))}
                                </ol> */}
                                <div dangerouslySetInnerHTML={{ __html: terms }} />
                                {termsHindi &&
                                    <div dangerouslySetInnerHTML={{ __html: termsHindi }} />
                                }
                            </div>
                        </div> :
                        // !isLoading && <p className="fs-6 text-center py-5 text-danger">{errors.apiresponse}</p>
                        !isLoading && null
                    }
                </div>
            </div>
            <footer className="Footer" style={{ marginTop: 50 }}>
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" alt="left arrow icon" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                    <div onClick={handleOnClick}>
                        <span className='fs-6'>NEXT</span>
                        <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer ms-3" alt="right arrow icon" />
                    </div>
                </div>
            </footer>
        </div >
    );
}

export default Booking;
