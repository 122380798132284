import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';

function Disclaimer() {
    const state = useSelector((state) => state);
    const { counter_id } = useParams();
    const navigate = useNavigate()

    const estateofmbsm = `<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        DISCLAIMER: The information contained in this e-mail is confidential and may be legally privileged. It is intended for the addressee(s) only. If you are not the intended recipient, please delete this e-mail and notify the mailadmin@eternalmewar.in : The contents of this e-mail must not be disclosed or copied without the sender's consent.
    </span>
</p>
<p>
    <span style="color: rgb(0, 0, 0);font-size: 11pt;">
        The statements and opinions expressed in this message are those of the author and Organisation does not take any responsibility for the views of the author.
    </span>
</p>
`
    const hrhhotels = `<p>
    DISCLAIMER: The information contained in this e-mail is confidential and may be legally privileged. It is intended for the addressee(s) only. If you are not the intended recipient, please delete this e-mail and notify the <a href="mailto:mailadmin@eternalmewar.in">mailadmin@eternalmewar.in</a>. The contents of this e-mail must not be disclosed or copied without the sender's consent.
</p>
<p>
    The statements and opinions expressed in this message are those of the author and the Organisation does not take any responsibility for the views of the author.
</p>
`

    const citypalacemuseum = `<p>
    <strong>DISCLAIMER:</strong> The information contained in this e-mail is confidential and may be legally privileged. It is intended for the addressee(s) only. If you are not the intended recipient, please delete this e-mail and notify the <a href="mailto:mailadmin@eternalmewar.in">mailadmin@eternalmewar.in</a>. The contents of this e-mail must not be disclosed or copied without the sender's consent.
</p>
<p>
    The statements and opinions expressed in this message are those of the author, and the Organisation does not take any responsibility for the views of the author.
</p>
`

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} counterId={counter_id} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">Disclaimer</h3>
                    <div dangerouslySetInnerHTML={{ __html: estateofmbsm }} />
                    {/* {window.location.href.includes('eticket.estateofmbsm.org') &&
                        <></>
                    }
                    {window.location.href.includes('eticket.hrhhotels.com') &&
                        <div dangerouslySetInnerHTML={{ __html: hrhhotels }} />
                    }
                    {window.location.href.includes('eticket.citypalacemuseum.org') &&
                        <div dangerouslySetInnerHTML={{ __html: citypalacemuseum }} />
                    } */}
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default Disclaimer;
